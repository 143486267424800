function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Windows Phone must come first because its UA also contains "Android"
    // if (/windows phone/i.test(userAgent)) {
    //     return "Windows Phone";
    // }

    if (/android/i.test(userAgent)) {
        return "Android";
    } else {
        return 'iOS';
    }

    // let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown';

    // let isIOS = (/iPad|iPhone|iPod/.test(userAgent) ||
    //     (platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
    //     !window.MSStream

    // // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // if (isIOS) {
    //     console.log(platform);
    //     return "iOS";
    // }
    
    // return "unknown";
}

function showContent(dataAttribute, href) {
    const a = document.querySelector('[data-button]');

    document.querySelector(dataAttribute).classList.add('show-content');
    a.classList.add('show-button');
    a.href = href;
}

function setProperContent() {
    const mobileSystem = getMobileOperatingSystem();
    console.log(mobileSystem);

    if (mobileSystem === "iOS") {
        showContent('[data-ios]', '/LegoObjectAR.reality');
    }

    if (mobileSystem === "Android") {
        showContent('[data-android]', 'https://viewer.city.ar-prototype.modelbox.bricks.plus/');
    }
    
}

setProperContent(); 
